var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-header-wrapper",
    [
      _c(
        "a-card",
        { attrs: { bordered: false } },
        [
          _c(
            "div",
            { staticClass: "table-page-search-wrapper" },
            [
              _c(
                "a-form",
                { attrs: { layout: "inline" } },
                [
                  _c(
                    "a-row",
                    { attrs: { gutter: 48 } },
                    [
                      _c(
                        "a-col",
                        { attrs: { md: 8, sm: 24 } },
                        [
                          _c(
                            "a-form-item",
                            { attrs: { label: "车辆" } },
                            [
                              _c("a-tree-select", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  replaceFields: _vm.replaceFields,
                                  "show-search": "",
                                  "tree-data": _vm.treeData,
                                  "show-checked-strategy": _vm.SHOW_PARENT,
                                  "tree-checkable": "",
                                  placeholder: "请选择车辆",
                                  treeNodeFilterProp: "title",
                                },
                                on: {
                                  select: _vm.treeSelect,
                                  search: _vm.treeSearch,
                                  change: _vm.treeChange,
                                  blur: _vm.treeBlur,
                                  focus: _vm.treeFocus,
                                },
                                model: {
                                  value: _vm.vehicleNos,
                                  callback: function ($$v) {
                                    _vm.vehicleNos = $$v
                                  },
                                  expression: "vehicleNos",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { md: 8, sm: 24 } },
                        [
                          _c(
                            "a-form-item",
                            { attrs: { label: "日期" } },
                            [
                              _c("a-range-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  valueFormat: "YYYY-MM-DD",
                                  format: "YYYY-MM-DD",
                                  "allow-clear": "",
                                },
                                model: {
                                  value: _vm.daterangeReportDate,
                                  callback: function ($$v) {
                                    _vm.daterangeReportDate = $$v
                                  },
                                  expression: "daterangeReportDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { md: (!_vm.advanced && 8) || 24, sm: 24 } },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "table-page-search-submitButtons",
                              style:
                                (_vm.advanced && {
                                  float: "right",
                                  overflow: "hidden",
                                }) ||
                                {},
                            },
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: { type: "primary" },
                                  on: { click: _vm.handleQuery },
                                },
                                [
                                  _c("a-icon", { attrs: { type: "search" } }),
                                  _vm._v("查询"),
                                ],
                                1
                              ),
                              _c(
                                "a-button",
                                {
                                  staticStyle: { "margin-left": "8px" },
                                  on: { click: _vm.resetQuery },
                                },
                                [
                                  _c("a-icon", { attrs: { type: "redo" } }),
                                  _vm._v("重置"),
                                ],
                                1
                              ),
                              _c(
                                "a",
                                {
                                  staticStyle: { "margin-left": "8px" },
                                  on: { click: _vm.toggleAdvanced },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.advanced ? "收起" : "展开") +
                                      " "
                                  ),
                                  _c("a-icon", {
                                    attrs: {
                                      type: _vm.advanced ? "up" : "down",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-toolbar",
            {
              attrs: { loading: _vm.loading, refresh: "", tid: "1" },
              on: { refreshQuery: _vm.getList },
            },
            [_c("div", { staticClass: "table-operations" })]
          ),
          _c("create-form", { ref: "createForm", on: { ok: _vm.getList } }),
          _c("div", { attrs: { id: "dMap" } }),
          _c("a-table", {
            attrs: {
              loading: _vm.loading,
              size: _vm.tableSize,
              rowKey: "id",
              tid: "1",
              columns: _vm.columns,
              "data-source": _vm.list,
              "row-selection": {
                selectedRowKeys: _vm.selectedRowKeys,
                onChange: _vm.onSelectChange,
              },
              pagination: false,
            },
            scopedSlots: _vm._u([
              {
                key: "reportDate",
                fn: function (text, record) {
                  return _c("span", {}, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.parseTime(record.reportDate).split(" ")[0]) +
                        " "
                    ),
                  ])
                },
              },
              {
                key: "ignitionFirstTime",
                fn: function (text, record) {
                  return _c("span", {}, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.parseTime(record.ignitionFirstTime)) +
                        " "
                    ),
                  ])
                },
              },
              {
                key: "ignitionLastTime",
                fn: function (text, record) {
                  return _c("span", {}, [
                    _vm._v(
                      " " + _vm._s(_vm.parseTime(record.ignitionLastTime)) + " "
                    ),
                  ])
                },
              },
              {
                key: "flameoutFirstTime",
                fn: function (text, record) {
                  return _c("span", {}, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.parseTime(record.flameoutFirstTime)) +
                        " "
                    ),
                  ])
                },
              },
              {
                key: "flameoutLastTime",
                fn: function (text, record) {
                  return _c("span", {}, [
                    _vm._v(
                      " " + _vm._s(_vm.parseTime(record.flameoutLastTime)) + " "
                    ),
                  ])
                },
              },
              {
                key: "timeSum",
                fn: function (text, record) {
                  return _c("span", {}, [
                    _vm._v(" " + _vm._s(_vm.huorsFormat(record.timeSum)) + " "),
                  ])
                },
              },
              {
                key: "operation",
                fn: function (text, record) {
                  return _c(
                    "span",
                    {},
                    [
                      _c("a-divider", {
                        directives: [
                          {
                            name: "hasPermi",
                            rawName: "v-hasPermi",
                            value: ["tenant:jt808:vehicleDailyReport:query"],
                            expression:
                              "['tenant:jt808:vehicleDailyReport:query']",
                          },
                        ],
                        attrs: { type: "vertical" },
                      }),
                      _c(
                        "a",
                        {
                          directives: [
                            {
                              name: "hasPermi",
                              rawName: "v-hasPermi",
                              value: ["tenant:jt808:vehicleDailyReport:query"],
                              expression:
                                "['tenant:jt808:vehicleDailyReport:query']",
                            },
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.$refs.createForm.handleUpdate(
                                record,
                                undefined
                              )
                            },
                          },
                        },
                        [
                          _c("a-icon", { attrs: { type: "edit" } }),
                          _vm._v("查看 "),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                },
              },
            ]),
          }),
          _c("a-pagination", {
            staticClass: "ant-table-pagination",
            attrs: {
              "show-size-changer": "",
              "show-quick-jumper": "",
              current: _vm.queryParam.pageNum,
              total: _vm.total,
              "page-size": _vm.queryParam.pageSize,
              showTotal: function (total) {
                return "共 " + total + " 条"
              },
            },
            on: {
              showSizeChange: _vm.onShowSizeChange,
              change: _vm.changeSize,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }