<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="车辆">
                <a-tree-select
                  v-model="vehicleNos"
                  :replaceFields="replaceFields"
                  show-search
                  style="width: 100%"
                  :tree-data="treeData"
                  :show-checked-strategy="SHOW_PARENT"
                  tree-checkable
                  @select="treeSelect"
                  @search="treeSearch"
                  @change="treeChange"
                  @blur="treeBlur"
                  @focus="treeFocus"
                  placeholder="请选择车辆"
                  treeNodeFilterProp="title"
                />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="日期">
                <a-range-picker
                  style="width: 100%"
                  v-model="daterangeReportDate"
                  valueFormat="YYYY-MM-DD"
                  format="YYYY-MM-DD"
                  allow-clear
                />
              </a-form-item>
            </a-col>
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <a-toolbar :loading="loading" refresh @refreshQuery="getList" tid="1">
        <div class="table-operations">
          <!-- <a-button
          type="primary"
          @click="$refs.createForm.handleAdd()"
          v-hasPermi="['tenant:jt808:vehicleDailyReport:add']"
        >
          <a-icon type="plus" />新增
        </a-button>
        <a-button
          type="primary"
          :disabled="single"
          @click="$refs.createForm.handleUpdate(undefined, ids)"
          v-hasPermi="['tenant:jt808:vehicleDailyReport:edit']"
        >
          <a-icon type="edit" />修改
        </a-button>
        <a-button
          type="danger"
          :disabled="multiple"
          @click="handleDelete"
          v-hasPermi="['tenant:jt808:vehicleDailyReport:remove']"
        >
          <a-icon type="delete" />删除
        </a-button>
        <a-button type="primary" @click="handleExport" v-hasPermi="['tenant:jt808:vehicleDailyReport:export']">
          <a-icon type="download" />导出
        </a-button> -->
          <!-- <a-button
            type="dashed"
            shape="circle"
            :loading="loading"
            :style="{ float: 'right' }"
            icon="reload"
            @click="getList"
          /> -->
        </div>
      </a-toolbar>
      <!-- 增加修改 -->
      <create-form ref="createForm" @ok="getList" />
      <div id="dMap"></div>
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        tid="1"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
      >
        <span slot="reportDate" slot-scope="text, record">
          {{ parseTime(record.reportDate).split(' ')[0] }}
        </span>
        <span slot="ignitionFirstTime" slot-scope="text, record">
          {{ parseTime(record.ignitionFirstTime) }}
        </span>
        <span slot="ignitionLastTime" slot-scope="text, record">
          {{ parseTime(record.ignitionLastTime) }}
        </span>
        <span slot="flameoutFirstTime" slot-scope="text, record">
          {{ parseTime(record.flameoutFirstTime) }}
        </span>
        <span slot="flameoutLastTime" slot-scope="text, record">
          {{ parseTime(record.flameoutLastTime) }}
        </span>
        <span slot="timeSum" slot-scope="text, record">
          {{ huorsFormat(record.timeSum) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['tenant:jt808:vehicleDailyReport:query']" />
          <a
            @click="$refs.createForm.handleUpdate(record, undefined)"
            v-hasPermi="['tenant:jt808:vehicleDailyReport:query']"
          >
            <a-icon type="edit" />查看
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { listVehicleDailyReport, delVehicleDailyReport, exportVehicleDailyReport } from '@/api/jt808/vehicleDailyReport'
import CreateForm from './modules/CreateForm'
import coordinateTransform from '@/utils/coordinateTransform'
import { vehicleAccountTree } from '@/api/iot/vehicleAccount'
import BMap from 'BMap'
import { TreeSelect } from 'ant-design-vue'
import { huorsFormat } from '@/utils/util'
const SHOW_PARENT = TreeSelect.SHOW_PARENT

export default {
  name: 'VehicleDailyReport',
  components: {
    CreateForm,
    ATreeSelect: TreeSelect
  },
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      SHOW_PARENT,
      // 日期时间范围
      daterangeReportDate: [],
      // 查询参数
      queryParam: {
        vehicleNo: null,
        vehicleNos: [],
        plateNo: null,
        reportDate: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '车辆编号',
          dataIndex: 'vehicleNo',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '车牌号',
          dataIndex: 'plateNo',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '日期',
          dataIndex: 'reportDate',
          scopedSlots: { customRender: 'reportDate' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '最早点火时间',
          dataIndex: 'ignitionFirstTime',
          scopedSlots: { customRender: 'ignitionFirstTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '最晚点火时间',
          dataIndex: 'ignitionLastTime',
          scopedSlots: { customRender: 'ignitionLastTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '最早点火位置',
          dataIndex: 'ignitionFirstAddress',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '最晚点火位置',
          dataIndex: 'ignitionLastAddress',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '累计行驶时间',
          dataIndex: 'timeSum',
          scopedSlots: { customRender: 'timeSum' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '累计行驶里程',
          dataIndex: 'odoSum',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
      replaceFields: { children: 'children', title: 'label', key: 'id', value: 'id' },
      treeData: [],
      vehicleNos: undefined
    }
  },
  filters: {},
  created() {
    this.getEmployeeAccountTree()
    this.initMap()
    this.getList()
  },
  computed: {},
  watch: {},
  methods: {
    huorsFormat,
    initMap() {
      console.log('this.BMap', this.BMap)
      // eslint-disable-next-line no-unused-vars
      const map = new BMap.Map('dMap')
      // map.centerAndZoom(point,12);
      this.geoc = new BMap.Geocoder()
      // 根据坐标得到地址描述
    },
    getAddress(lng, lat) {
      const coo = this.latAndlangTansfrom(lng, lat)
      console.log('coo', coo)
      return new Promise((resolve, reject) => {
        this.geoc.getLocation(new BMap.Point(coo.lng, coo.lat), function (result) {
          console.log('getLocation1', result)
          if (result) {
            console.log('getLocation', result)
            resolve(result.address)
          }
        })
      })
    },
    /** 查询车辆每日报表列表 */
    getList() {
      this.loading = true
      this.queryParam.params = {}
      if (
        this.daterangeReportDate !== null &&
        this.daterangeReportDate !== '' &&
        this.daterangeReportDate.length !== 0
      ) {
        this.queryParam.params['beginReportDate'] = this.daterangeReportDate[0]
        this.queryParam.params['endReportDate'] = this.daterangeReportDate[1]
      }
      listVehicleDailyReport(this.queryParam).then(async (response) => {
        this.list = response.rows
        // for (const item of this.list) {
        //   item.ignitionFirstAddress = await this.getAddress(
        //     item.ignitionFirstLongitude > 100000 ? item.ignitionFirstLongitude / 100000 : item.ignitionFirstLongitude,
        //     item.ignitionFirstLatitude > 100000 ? item.ignitionFirstLatitude / 100000 : item.ignitionFirstLatitude
        //   )
        //   item.ignitionLastAddress = await this.getAddress(
        //     item.ignitionLastLongitude > 100000 ? item.ignitionLastLongitude / 100000 : item.ignitionLastLongitude,
        //     item.ignitionLastLatitude > 100000 ? item.ignitionLastLatitude / 100000 : item.ignitionLastLatitude
        //   )
        // }
        this.total = response.total
        this.loading = false
      })
    },
    getEmployeeAccountTree() {
      vehicleAccountTree().then((response) => {
        this.treeData = response.data
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.daterangeReportDate = []
      this.queryParam = {
        vehicleNo: undefined,
        vehicleNos: undefined,
        plateNo: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.vehicleNos = undefined
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    latAndlangTansfrom(lng, lat) {
      return coordinateTransform.wgs84togcj02tobd09(lng, lat)
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          return delVehicleDailyReport(ids).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {}
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          return exportVehicleDailyReport(that.queryParam).then((response) => {
            that.download(response.msg)
            that.$message.success('导出成功', 3)
          })
        },
        onCancel() {}
      })
    },
    treeChange(e) {
      console.log('treeChange', e)
      this.treeChangeValue = e
      let vechileArr = []
      for (const item of this.treeChangeValue) {
        this.curFlag = false
        this.curVechileArr = []
        console.log('treeChangeValue——item', item)
        this.searchDeptAllVehicle(item)
        vechileArr = vechileArr.concat(this.curVechileArr)
      }
      this.queryParam.vehicleNos = []
      for (const item of vechileArr) {
        this.queryParam.vehicleNos.push(item.label.split('(')[0])
      }
      console.log('vechileArr', vechileArr)
    },
    treeSelect(e) {
      console.log('treeSelect', e)
    },
    treeBlur(e) {
      console.log('treeBlur', e)
    },
    treeFocus(e) {
      console.log('treeFocus', e)
    },
    searchDeptAllVehicle(id, children) {
      const data = children || this.treeData
      for (const item of data) {
        if (!id || item.id === id) {
          this.curFlag = true
          if (item.children && item.children.length > 0) {
            this.searchDeptAllVehicle(null, item.children)
          } else {
            this.curVechileArr.push(item)
          }
        } else {
          if (!this.curFlag && item.children) {
            this.searchDeptAllVehicle(id, item.children)
          }
        }
      }
    },
    treeSearch(e) {
      console.log('treeSearch', e)
    }
  }
}
</script>
